.nav_container {
  width: 90% !important;
  margin: auto;
}
.brand {
  color: #fff !important;
}
.nav_link {
  padding: 2px 20px;
  border-radius: 25px;
  margin: auto 5px;
  text-decoration: none;
  &.login {
    border: 1px solid #fff;
    color: #fff;
  }
  &.signup {
    border: none;
    color: #000;
    background-color: #fbca32;
  }
}
.signup_container {
  text-align: center;
  margin: 10% auto;
  h1 {
    margin: 20px auto;
    color: #fff;
  }
  form {
    width: 50%;
    margin: auto;
    text-align: center;
    .form-control {
      width: 50%;
      margin: auto;
      background-color: transparent !important;
      //   border: #fff !important;
      color: #fff !important;
    }
    .submit_btn {
      width: 35%;
      background-color: #fbca32 !important;
      padding: 10px;
      border: none;
      color: #000;
      border-radius: 25px;
    }
  }
}
.login_container {
  text-align: center;
  margin: 10% auto;
  h1 {
    margin: 20px auto;
    color: #fff;
  }
  form {
    width: 50%;
    margin: auto;
    text-align: center;
    .form-control {
      width: 50%;
      margin: auto;
      background-color: transparent !important;
      //   border: #fff !important;
      color: #fff !important;
    }
    .submit_btn {
      width: 35%;
      background-color: #fbca32 !important;
      padding: 10px;
      border: none;
      color: #000;
      border-radius: 25px;
    }
  }
}

.home_container {
  img {
    width: 100%;
    height: 100%;
  }
}
